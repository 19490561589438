
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, NavLink, HashRouter, Switch } from "react-router-dom";
import axios from 'axios';
import NewQRCode from './NewQRCode';
import Details from './Details';
import QRCode from './QRCode';
import Statistics from './Statistics';
import UpdateQRCode from './UpdateQRCode';
import { Form } from 'react-bootstrap';
import { getAppServer } from './api/values'

class ListAllQRCodes extends React.Component {
    constructor() {
        super();
        this.state = {
            SourceData: [],
            BrandsList: [],
            SelectedBrand: 'default',
            Filter: 'All',
            sort: {
                column: null,
                direction: 'desc',
            }
        }
        this.archiveTarget = this.archiveTarget.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onSort = this.onSort.bind(this);
    }
    componentDidMount() {
        var that = this;
        axios.all([
            axios.get(getAppServer() + '/api/QRCodeApi/GetBrands'),
        ])
        .then(axios.spread(function (getBrands) {
            that.setState({
                BrandsList: getBrands.data,
            })
        }))
        .catch(error => {
            console.log(error)
        })
    }

    handleFilterChange(event) {
        if (event.target.value == 'default') {
            axios.get(getAppServer() + '/api/QRCodeAPI/Get')
                .then(response => {
                    this.setState({
                        SourceData: response.data
                    })
                })
                .catch(error => {
                    console.log(error)
                })
            this.setState({ SelectedBrand: event.target.value })
        } else {
            axios.get(getAppServer() + '/api/QRCodeAPI/GetTargetsByBrand?brandId=' + event.target.value)
                .then(response => {
                    this.setState({
                        SourceData: response.data
                    })
                })
                .catch(error => {
                    console.log(error)
                })
            this.setState({ SelectedBrand: event.target.value })
        }
    }
    uniqueOptions = (objectsArray, objectKey) => {
        var brand = objectsArray.map((o, i) => {
            return o[objectKey]
        })
        return brand.filter(function (i, index) {
            return brand.indexOf(i) >= index
        })
    }
    archiveTarget(id, event) {

        var confirmation = confirm("Are you sure you want to put this to the archive?")

        if (confirmation == true) {
            axios.put(getAppServer() + '/api/QRCodeAPI/ArchiveTarget?targetId=' + id)
                .then(Response => {
                    location.reload()
                })
                .catch(error => {
                    console.log(error)
                })
        } 
    }
    onSort(column) {
        return (function (e) {
            let direction = this.state.sort.direction
            const sortedData = this.state.SourceData
            if (this.state.sort.column === column) {
                direction = this.state.sort.direction === 'asc' ? 'desc' : 'asc'
            }
            sortedData.sort((a, b) => {
                if (column === 'CreationDate') {
                    return new Date(a.CreationDate) - new Date(b.CreationDate)
                } else if (column === 'EditedAt') {
                    return new Date(a.Target.EditedAt) - new Date(b.Target.EditedAt)
                } else if (column === 'Title') {
                    const collator = new Intl.Collator('sv')
                    return collator.compare(a.Target.Title, b.Target.Title)
                }
            })
            if (direction === 'desc') {
                sortedData.reverse()
            }
            this.setState({
                SourceData: sortedData,
                sort: {
                    column,
                    direction
                }
            })
        }).bind(this)
    }
    setArrow = (column) => {
        let className = 'sort-direction'
        if (this.state.sort.column === column) {
            className += this.state.sort.direction === 'asc' ? ' asc' : ' desc'
        }
        return className
    }
    render() {
        return (
            <HashRouter>
                <div className="container moveDown">
                    <div className="row">
                        <div className="col-6">
                            <select className="browser-default custom-select" value={this.state.SelectedBrand}
                                onChange={this.handleFilterChange}>
                                <option value="default">Filter..</option>
                                {this.state.BrandsList.map((brand) => <option key={brand.BrandId} value={brand.BrandId}>{brand.BrandName}</option>)}
                            </select>
                            <table className="table table-striped">
                                <thead>
                                    <tr className="d-flex">
                                        <th className="col-5 sortable" onClick={this.onSort('Title')} scope="col">Details<span className={this.setArrow('Title')}></span></th>
                                        <th className="nowrap sortable text-center" onClick={this.onSort('CreationDate')} scope="col">Created<span className={this.setArrow('CreationDate')}></span></th>
                                        <th className="nowrap sortable text-center" onClick={this.onSort('EditedAt')} scope="col">Updated<span className={this.setArrow('EditedAt')}></span></th>
                                        <th className="text-center" scope="col"></th>
                                        <th className="text-center" scope="col"></th>
                                        <th className="text-center" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.SourceData.map((source, index) => {
                                            return (
                                                <tr className="d-flex" key={index}>
                                                    <td className="col-5"><NavLink to={"/details/" + source.SourceId} style={{ color: "black" }}>{source.Target.Title}</NavLink></td>
                                                    <td className="nowrap monetary text-center font-weight-light">{new Intl.DateTimeFormat('default').format(new Date(source.CreationDate))}</td>
                                                    <td className="nowrap text-center font-weight-light">{new Intl.DateTimeFormat('default').format(new Date(source.Target.EditedAt))}</td>
                                                    <td className="text-center"><NavLink to={"/qrcode/" + source.SourceId} style={{ color: "black" }}><i className="fas fa-qrcode"></i></NavLink></td>
                                                    <td className="text-center"><NavLink to={"/edit/" + source.SourceId} style={{ color: "black" }}><i className="fas fa-edit"></i></NavLink></td>
                                                    <td className="text-center"><NavLink><div className="text-warning" onClick={this.archiveTarget.bind(this, source.TargetId)}><i className="fas fa-archive"></i></div></NavLink></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                                <button className="btn btn-dark"><NavLink to="/new" style={{ color: "white"}}>Add new</NavLink></button>

                        
                        </div>
                        <div className="col-6">
                            <Switch>
                                <Route path="/new" component={NewQRCode} />
                                <Route path="/details/:id" component={Details} />
                                <Route path="/qrcode/:id" component={QRCode} />
                                <Route path="/statistics/:id" component={Statistics} />
                                <Route path="/edit/:id" component={UpdateQRCode} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </HashRouter>
            )
    }
} 
ReactDOM.render(<ListAllQRCodes />, document.getElementById('reactNav'));
