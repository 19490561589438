
import React from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { getAppServer } from './api/values'

class QRCode extends React.Component {
    constructor() {
        super();
        this.state = {
            targetTitle: '',
            svgFile: '',
            targetUrl: ''
        }
    }
    componentWillMount() { 
        var that = this;
        axios.all([
            axios.get(getAppServer() + '/api/QRCodeAPI/GenerateQRCode?sourceId=' + this.props.match.params.id),
            axios.get(getAppServer() + '/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
        ])
        .then(axios.spread(function (svgFile, targetDetails) {
        console.log(targetDetails.data)
        that.setState({
            svgFile: svgFile.data[0],
            targetTitle: targetDetails.data.Title,
            targetUrl: targetDetails.data.URL
        })
            
        var image = document.getElementById("svgImage")
        image.innerHTML = svgFile.data
            
        var downloadButton = document.getElementById("download")
            downloadButton.onclick = function () { fileDownload(svgFile.data, targetDetails.data.Title+'.svg') }
        }))
        .catch(error => {
            console.log(error)
        })
    }
    componentDidUpdate(nextParams) {
        if (nextParams.match.params.id !== this.props.match.params.id) {
            var that = this;
            axios.all([
                axios.get(getAppServer() + '/api/QRCodeAPI/GenerateQRCode?sourceId=' + this.props.match.params.id),
                axios.get(getAppServer() + '/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
            ])
                .then(axios.spread(function (svgFile, targetDetails) {
                    console.log(targetDetails.data)
                    that.setState({
                        svgFile: svgFile.data[0],
                        targetTitle: targetDetails.data.Title,
                        targetUrl: targetDetails.data.URL
                    })

                    var image = document.getElementById("svgImage")
                    image.innerHTML = svgFile.data

                    var downloadButton = document.getElementById("download")
                    downloadButton.onclick = function () { fileDownload(svgFile.data, targetDetails.data.Title + '.svg') }
                }))
                .catch(error => {
                    console.log(error)
                })
        }
    }
    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title>{this.state.targetTitle}</Card.Title>
                    <Card.Subtitle>{this.state.targetUrl}</Card.Subtitle>
                    <hr />
                    <p id="svgImage"></p>
                    <button className="btn btn-dark" id="download"><i className="fa fa-download" aria-hidden="true"></i></button>
                </Card.Body>
            </Card>
        );
    }
}
export default QRCode;

