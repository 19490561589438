import React from 'react';
import axios from 'axios';
import { Form, Button, Col } from 'react-bootstrap';
import { getAppServer } from './api/values'

class UpdateQRCode extends React.Component {
    constructor() {
        super();
        this.state = {
            qrData: [],
            BrandsList: [],
            Title: '',
            URL: '',
            Brand: '',
            BrandId: '',
            Description: ''
        }
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleURLChange = this.handleURLChange.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() { 
        var that = this
        axios.all([
            axios.get(getAppServer() + '/api/QRCodeApi/GetBrands'),
            axios.get(getAppServer() + '/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
        ])
        .then(axios.spread(function (getBrands, source) {
            that.setState({
                BrandsList: getBrands.data,
                Title: source.data.Title,
                Description: source.data.Description,
                Brand: source.data.Brand,
                URL: source.data.URL,
                BrandId: source.data.BrandId
            })
        }))
        .catch(error => {
            console.log(error)
        })
    }
    componentDidUpdate(nextParams) {
        if (nextParams.match.params.id !== this.props.match.params.id) {
            var that = this
            axios.all([
                axios.get(getAppServer() + '/api/QRCodeApi/GetBrands'),
                axios.get(getAppServer() + '/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
            ])
            .then(axios.spread(function (getBrands, source) {
                that.setState({
                    BrandsList: getBrands.data,
                    Title: source.data.Title,
                    Description: source.data.Description,
                    Brand: source.data.Brand,
                    URL: source.data.URL,
                    BrandId: source.data.BrandId
                })
            }))
            .catch(error => {
                console.log(error)
            })
        }
    }
    
    handleTitleChange(event) { this.setState({ Title: event.target.value }) }
    handleURLChange(event) { this.setState({ URL: event.target.value }) }
    handleBrandChange(event) { this.setState({ BrandId: event.target.value }) }
    handleDescriptionChange(event) { this.setState({ Description: event.target.value }) }

    handleSubmit(event) {
        event.preventDefault();
        const qrCode = {
            Title: this.state.Title,
            URL: this.state.URL,
            BrandId: this.state.BrandId,
            Description: this.state.Description
        }
        axios.put(getAppServer() + '/api/QRCodeAPI/UpdateQRCode?sourceId=' + this.props.match.params.id,
            qrCode
        )
        .then(response => {
            if (response.data.result.includes("Updated")) {
                document.getElementById("edit-new-success").classList.add("alert")
                document.getElementById("edit-new-success").classList.add("alert-success")
                document.getElementById("edit-new-success").innerText = response.data.result
                //location.reload()
            } else {
                document.getElementById("edit-new-errors").classList.add("alert")
                document.getElementById("edit-new-errors").classList.add("alert-danger")
                document.getElementById("edit-new-errors").innerText = response.data.result
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
    render() {
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control name="Title" value={this.state.Title} onChange={this.handleTitleChange} />
                        </Form.Group>


                        <Form.Group as={Col}>
                            <Form.Label>Brand</Form.Label>
                            <select className="browser-default custom-select" value={this.state.BrandId}
                                onChange={this.handleBrandChange}>
                                {this.state.BrandsList.map((brand) => <option key={brand.BrandId} value={brand.BrandId}>{brand.BrandName}</option>)}
                            </select>
                        </Form.Group>
                    </Form.Row>
                    

                    <Form.Group>
                        <Form.Label>Url</Form.Label>
                        <Form.Control name="URL" value={this.state.URL} onChange={this.handleURLChange} />
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="6" name="Description" value={this.state.Description} onChange={this.handleDescriptionChange} />
                    </Form.Group>


                    <Button variant="dark" type="submit">
                        Update
                    </Button>
                </Form>
                <br />
                <p id="edit-new-errors"></p>
                <p id="edit-new-success"></p>
            </div>
        );
    }
}
export default UpdateQRCode;






