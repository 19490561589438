import React from 'react';
import axios from 'axios';
import { Form, Button, Col } from 'react-bootstrap';
import { getAppServer } from './api/values'

class NewQRCode extends React.Component {
    constructor() {
        super();
        this.state = {
            BrandsList: [],
            Title: '',
            URL: '',
            SelectedBrand: '',
            Description: ''
        }
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleURLChange = this.handleURLChange.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        axios.get(getAppServer() + '/api/QRCodeApi/GetBrands')
            .then(Response => {
                this.setState({
                    BrandsList: Response.data,
                    SelectedBrand: Response.data[0].BrandId
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleTitleChange(event) { this.setState({ Title: event.target.value }) }
    handleURLChange(event) { this.setState({ URL: event.target.value }) }
    handleBrandChange(event) { this.setState({ SelectedBrand: event.target.value }) }
    handleDescriptionChange(event) { this.setState({ Description: event.target.value }) }

    handleSubmit(event) {
        event.preventDefault();
        const qrCode = {
            Title: this.state.Title,
            URL: this.state.URL,
            BrandId: this.state.SelectedBrand,
            Description: this.state.Description
        }
        axios.post(getAppServer() + '/api/QRCodeAPI/NewQRCode', 
                qrCode
            )
            .then(response => {
                
                if (response.data.result.includes("Created")) {
                    document.getElementById("create-new-success").classList.add("alert")
                    document.getElementById("create-new-success").classList.add("alert-success")
                    document.getElementById("create-new-success").innerText = response.data.result
                    location.reload()
                } else {
                    document.getElementById("create-new-errors").classList.add("alert")
                    document.getElementById("create-new-errors").classList.add("alert-danger")
                    document.getElementById("create-new-errors").innerText = response.data.result
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    render() {
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control name="Title" value={this.state.Title} onChange={this.handleTitleChange} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Brand</Form.Label>
                            <select className="browser-default custom-select" value={this.state.SelectedBrand}
                                onChange={this.handleBrandChange}>
                                {this.state.BrandsList.map((brand) => <option key={brand.BrandId} value={brand.BrandId}>{brand.BrandName}</option>)}
                            </select>
                        </Form.Group>
                    </Form.Row>
                

                    <Form.Group>
                        <Form.Label>Url</Form.Label>
                        <Form.Control name="URL" value={this.state.URL} onChange={this.handleURLChange} />
                    </Form.Group>
                
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="6" name="Description" value={this.state.Description} onChange={this.handleDescriptionChange} />
                    </Form.Group>
                

                    <Button variant="dark" type="submit">
                        Create
                    </Button>
                </Form>
                <br />
                <p id="create-new-errors"></p>
                <p id="create-new-success"></p>
            </div>
        );
    }
}
export default NewQRCode;






