import React from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap'
import { getAppServer } from './api/values'

class Statitics extends React.Component {
    constructor() {
        super();
        this.state = {
            Statistics: '',
            Title: '',
        }
    }
    componentDidMount() {
        axios.get(getAppServer() + '/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
            .then(response => {
                console.log(response)
                this.setState({
                    Title: response.data[0].Target.Title,
                    Statistics: response.data[0].Statistics
                })
            })
            .catch(error => {
                console.log(error)
            })
    }
    componentDidUpdate(nextParams) {
        if (nextParams.match.params.id !== this.props.match.params.id) {
            axios.get(getAppServer() + '/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
                .then(response => {
                    //console.log(response)
                    this.setState({
                        Title: response.data[0].Target.Title,
                        Statistics: response.data[0].Statistics
                    })
                    //console.log(this.state.Sources)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
    render() {
        return (
            <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title>{this.state.Title}</Card.Title>
                    <hr />
                        <Card.Text>
                            Statistics: {this.state.Statistics}
                        </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}
export default Statitics;