import React from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap'
import { Route, NavLink, HashRouter, Switch } from "react-router-dom";
import QRCode from './QRCode';
import { getAppServer} from './api/values'


class Details extends React.Component {
    constructor() {
        
        super();
        this.state = {
            SourceId: '',
            TargetId: '',
            Title: '',
            URL: '',
            Brand: '',
            Description: '',
            CreationDate: '',
            EditedAt: ''
        }
    }
    componentDidMount() {
         axios.get(getAppServer()+'/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
            .then(response => {
                this.setState({
                    SourceId: response.data.SourceId,
                    Description: response.data.Description,
                    TargetId: response.data.TargetId,
                    Title: response.data.Title,
                    URL: response.data.URL,
                    Brand: response.data.Brand,
                    CreationDate: new Date(response.data.CreationDate),
                    EditedAt: new Date(response.data.EditedAt)
                })
                //console.log(new Intl.DateTimeFormat('default').format(new Date(this.state.CreationDate)))
            })
            .catch(error => {
                console.log(error)
            })
    }
    componentDidUpdate(nextParams) {
        if (nextParams.match.params.id !== this.props.match.params.id) {
            axios.get(getAppServer()+'/api/QRCodeAPI/get?sourceId=' + this.props.match.params.id)
                .then(response => {
                    this.setState({
                        SourceId: response.data.SourceId,
                        Description: response.data.Description,
                        TargetId: response.data.TargetId,
                        Title: response.data.Title,
                        URL: response.data.URL,
                        Brand: response.data.Brand,
                        CreationDate: new Date(response.data.CreationDate),
                        EditedAt: new Date(response.data.EditedAt)
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
    render() {
        return (
            <Card style={{width: '25rem'}}>
                <Card.Body>
                    <Card.Title>{this.state.Title}</Card.Title>
                    <Card.Subtitle>{this.state.Brand}</Card.Subtitle>
                    <hr />
                    <Card.Text>
                        <NavLink to={"/qrcode/" + this.state.SourceId} style={{ color: "black" }}>{this.state.URL}</NavLink>
                    </Card.Text>
                    <Card.Text>
                        {this.state.Description}
                    </Card.Text>
                    <Card.Text className="font-weight-light">
                        Created: {new Intl.DateTimeFormat('default').format(this.state.CreationDate)}
                        <br />
                        Last updated: {new Intl.DateTimeFormat('default').format(this.state.EditedAt)}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}
export default Details